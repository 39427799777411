import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Axios from './Axios';
const Users = () => {
  const location = 'https://';
  const mailto = 'mailto:';
  const [usersList, setData] = useState([]);
  useEffect(() => {  
  Axios.get('/users')
    .then(response =>setData(response.data)
  )
  },[]);
  
  return (
    <>
      <table className="table table-bordered table-striped ">
        <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Website</th>
              <th>Action</th>
            </tr>
        </thead>
        <tbody>
          {usersList.map(item => <tr key={item.id}>
            <td>{item.name}</td>
            <td><a href={mailto + item.email.toLowerCase()}>{item.email}</a></td>
            <td>{item.phone}</td>
            <td>
              <a href={location + item.website} rel="noopener noreferrer" target="_blank">{location + item.website}</a>
            </td>
            <td>
                <Link to={`/userdetail/${item.id}`} className="btn btn-primary btn-sm">Detail</Link>
            </td>
          </tr>)}
          </tbody>
      </table>
    </>
  )
}

export default Users