import React from 'react'
import {Link} from 'react-router-dom'
const About = () => {
  return (
    <>
      <div>About</div>
      <Link to="/">Home</Link>
    </>
  )
}

export default About