import React from 'react';
import { BrowserRouter, Routes, Route,} from 'react-router-dom';
import Dashboard from './Dashboard';
import Home from './Home';
import About from './About';
import Users from './Users';
import UserDetail from './UserDetail';

const App = () => {
  
  return (
    <div className='container pt-5'>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="about" element={<About />} />
          <Route path="users" element={<Users />} />
          <Route path="userdetail/:userId" element={<UserDetail />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App