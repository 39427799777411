import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Axios from './Axios';
const UserDetail = () => {
    const location = 'https://';
    const { userId } = useParams();
    const [userinfo, setDetail] = useState([]);
    useEffect(() => {  
        Axios.get(`/users/${userId}`)
            .then(response =>setDetail(response.data)
        )
    });
    
    return (
        <>
            <div className="text-end">
                <Link to='/users'>Back to Users</Link>
                </div>
            <h2 className="mb-5">User Detail {userId}</h2>
            
            <div className="row">
                <div className="col-md-2">
                        <label>Name</label>
                </div>
                <div className="col-md-10">
                    <b>{ userinfo.name}</b>
                </div>
                <div className="col-md-2">
                        <label>Email</label>
                </div>
                <div className="col-md-10">
                    <b>{ userinfo.email}</b>
                </div>
                <div className="col-md-2">
                        <label>Phone</label>
                </div>
                <div className="col-md-10">
                    <b>{ userinfo.phone}</b>
                </div>
                <div className="col-md-2">
                        <label>Website</label>
                </div>
                <div className="col-md-10">
                    <b><a href={location + userinfo.website} rel="noopener noreferrer" target="_blank">{location + userinfo.website}</a></b>
                </div>
                 <div className="col-md-2">
                        <label>Address</label>
                </div>
                <div className="col-md-10">
                    <b>{userinfo.address && userinfo.address.street},</b><br />
                    <b>{userinfo.address && userinfo.address.suite },</b> <br />
                    <b>{userinfo.address && userinfo.address.city},</b> <br />
                    <b>{userinfo.address && userinfo.address.zipcode}.</b>
                </div>
                <div className="col-md-2">
                        <label>Company</label>
                </div>
                <div className="col-md-10">
                    <b>{userinfo.company && userinfo.company.name}, </b> <br />
                    <b>{userinfo.company && userinfo.company.catchPhrase }, </b> <br />
                    <b>{userinfo.company && userinfo.company.bs}.</b>
                </div>
             </div>
        </>
    )
}
export default UserDetail;